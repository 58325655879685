var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Estimate__form", {
    attrs: {
      is_template: _vm.is_template,
      groups: _vm.groups,
      clone_rule: _vm.clone_rule,
      copied_form: _vm.copied_form,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }